
            @import 'src/styles/mixins.scss';
        
.checkbox {
    .option {
        input {
            &:checked + label .box svg path {
                stroke-dashoffset: 0;
            }
            &:checked + label .box {
                background-color: white;
            }
        }
        label {
            display: flex;
            align-items: center;
            min-height: 46px;
            .box {
                display: block;
                border: 1px solid black;
                width: 15px;
                height: 15px;
                border-radius: 1px;
                cursor: pointer;
                transition: all 0.2s ease;
                margin-right: 15px;
                padding: 2px;

                svg {
                    pointer-events: none;
                    path {
                        fill: none;
                        stroke: white;
                        stroke-width: 6px;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 100;
                        stroke-dashoffset: 101;
                        transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
                    }
                }
            }
        }
    }
}
.checkbox-field {
    overflow-x: unset !important;

    & > div {
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        input {
            &:checked + label {
                border: 2px solid var(--primary, #ff8b00);
                span {
                    &:first-child {
                        @include handleAllBreakpoints(
                            "background",
                            "checkboxTicBoxBackgroundColor::checkboxSelectedBackgroundColor",
                            "var(--global-form-checkbox-normal-check-background-color)"
                        );
                        border-color: black !important;
                    }
                }
            }
        }
        label {
            border-radius: 3px;
            display: flex;
            align-items: center;
            margin: 0 auto;
            text-align: left;
            box-shadow: 0 1px 5px 0 #00000025;
            border: 2px solid transparent;
            @include handleAllBreakpoints("color", "thankYouTextColor", "black", "!important");
            @include md {
                min-height: 50px;
            }

            span {
                text-align: left;
                &:first-child {
                    min-width: 15px;
                    background-color: #f3f2f2;
                    @include handleAllBreakpoints("border", "checkboxTicBoxBorder", "1px solid #dedede", "!important");
                    border-radius: 3px !important;
                    margin-right: 10px !important;
                    svg {
                        path {
                            @include handleAllBreakpoints("stroke", "checkboxTicBoxColor", "white");
                        }
                    }
                }
            }
        }
    }
}

.select-all-checkbox-field {
    min-height: unset !important;
    margin-top: 0 !important;
    & > div {
        display: flex;

        input {
            &:checked + label {
                text-align: left;
                span {
                    &:first-child {
                        @include handleAllBreakpoints(
                            "background",
                            "checkboxTicBoxBackgroundColor::checkboxSelectedBackgroundColor",
                            "var(--global-form-checkbox-normal-check-background-color)"
                        );
                        border-color: black !important;
                    }
                }
            }
        }
        label {
            padding: 0 10px !important;
            min-height: unset !important;

            // @include handleAllBreakpoints("font-size", "checkboxFontSize", ":14px");
            display: flex;
            align-items: center;
            color: #5dbbff;

            .box {
                margin-right: 0 !important;
            }

            span {
                font-size: 12px;
                @include sm {
                    font-size: 14px;
                }

                &:first-child {
                    min-width: 15px;
                    background-color: #f3f2f2;
                    @include handleAllBreakpoints("border", "checkboxTicBoxBorder", "1px solid #dedede", "!important");
                    border-radius: 3px !important;
                    margin-right: 10px !important;
                    svg {
                        path {
                            @include handleAllBreakpoints("stroke", "checkboxTicBoxColor", "white");
                        }
                    }
                }
            }
        }
    }
}

.checkbox-icon {
    max-width: 70px !important;
    display: none;
    img {
        width: 100% !important;
        max-width: 100% !important;
        object-fit: contain !important;
        height: 2rem !important;
    }
    @include md {
        display: block;
    }
}

.title,
.description {
    @include handleAllBreakpoints("color", "thankYouTextColor", "black", "!important");
}
